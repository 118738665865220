<template>
  <div class="halls-boothclaim">
    <Modal
      title="展位认领"
      v-model="showModal"
      class-name="vertical-center-modal boothclaim myModel"
      width="650px"
      :mask-closable="false"
      @on-cancel="close()"
    >
      <div class="halls-boothclaim-content">
        <Form ref="formInline" :model="formInline" :rules="ruleInline" :label-width="120">
          <FormItem label="展位号：" prop="boothNum">
            <Input
              type="text"
              v-model="formInline.boothNum"
              @on-focus="codeFlag = false;"
              @on-blur="codeFlag = false;"
              @on-change="changeCodeList"
              placeholder="请输入展位号"
              :disabled="boothCode ? true : false"
            />
            <div v-if="codeList && codeList.length > 0 && codeFlag" class="code-list">
              <Option
                v-for="item in codeList"
                :value="item"
                :key="item"
                @click.native="formInline.boothNum = item;codeFlag = false;"
              >{{item}}</Option>
            </div>
          </FormItem>
          <FormItem label="公司名称：" prop="comName">
            <Input type="text" v-model="formInline.comName" placeholder="请输入公司名称" />
          </FormItem>
          <FormItem label="联系人：" prop="person">
            <Input type="text" v-model="formInline.person" placeholder="请输入联系人" />
          </FormItem>
          <FormItem label="手机：" prop="tel">
            <Input type="text" v-model="formInline.tel" placeholder="请输入手机号码" />
          </FormItem>
          <FormItem label="备注：" prop="content">
            <Input type="textarea" v-model="formInline.content" :rows="6" placeholder="请输入内容" />
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" :loading="loading" @click="submit('formInline')">提交</Button>
        <Button @click="close()">取消</Button>
      </div>
    </Modal>

    <!-- 提交成功后弹窗 -->
    <Modal
      v-model="successModal"
      class-name="vertical-center-modal myModel"
      width="460px"
      :mask-closable="false"
      footer-hide
      closable
      @on-cancel="closeSuc()"
    >
      <div class="successContent">
        <img src="./img/bg-info.png" alt />
        <p>信息提交成功</p>
        <span>请等待主办审核，或联系客服</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    boothClaimFlag: {
      type: Boolean,
      default: false
    },
    boothCode: {
      type: String,
      default: ""
    },
  },
  watch: {
    boothClaimFlag(val, oldVal) {
      this.showModal = val;
      if (!val) {
        this.close();
      }
    },
    boothCode(val, oldVal) {
      if (val) {
        this.formInline.boothNum = val;
      }
    },
    HallList(val, oldVal) {
      this.init();
    },
  },
  computed: {
    ...mapGetters({
      UserInfo: "getUser",
      // EntranceInfo: "getEntranceInfo",
      exhibitionInfo:'getExhibitionInfo',
      token:'getToken'
    }),
  },
  data() {
    const telValid = (rule, value, callback) => {
      if (!value) {
        callback("手机号码不能为空");
      } else if (/^[1][0-9]{10}$/.test(value)) {
        callback();
      } else {
        callback("请输入正确的手机号码");
      }
    };
    const boothCode = (rule, value, callback) => {
      if (!value) {
        callback("展位号不能为空");
      } else if (this.flag === 1) {
        callback("该展位号不存在");
      } else if (this.flag === 2) {
        callback("该展位已有展商入驻，请联系主办方询问");
      } else {
        callback();
      }
    };
    return {
      showModal: false,
      loading: false,
      HallListData: [],
      formInline: {
        Hall: "", // 展馆
        boothNum: "", // 展位号
        comName: "", // 公司名称
        person: "", // 联系人
        tel: "", // 手机号码
        content: "" // 备注
      },
      ruleInline: {
        boothNum: [{ required: true, validator: boothCode, trigger: "blur" }],
        comName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" }
        ],
        person: [
          { required: true, message: "联系人不能为空", trigger: "blur" }
        ],
        tel: [{ required: true, validator: telValid, trigger: "blur" }],
        content: [
          { required: true, message: "备注内容不能为空", trigger: "blur" }
        ]
      },
      successModal: false,
      boothCodeInfo: [], // 当前展会下的展位信息
      flag: 0,
      codeList: [],
      codeFlag: false
    };
  },
  methods: {
    ...mapActions({
      boothClaim: "hall/boothClaim",
      graphqlPostByTu:'graphqlPostByTu2',
    }),
    changeCodeList() {
      this.codeFlag = true;
      let codeList = [];
      if (this.boothCodeInfo && this.boothCodeInfo.length > 0) {
        this.boothCodeInfo.map(c => {
          if (
            c.BoothCode.indexOf(this.formInline.boothNum) !== -1 &&
            this.formInline.boothNum !== ""
          ) {
            codeList.push(c.BoothCode);
          }
        });
      }
      this.codeList = codeList;
    },
    init() {
      if (this.UserInfo) {
        this.formInline.comName = this.UserInfo.company;
        this.formInline.person = this.UserInfo.name || this.UserInfo.nickName;
        this.formInline.tel = this.UserInfo.phone;
      }
    },
    close() {
      this.flag = 0;
      this.$refs.formInline.resetFields();
      this.$emit("closeboothClaim");
      if (this.UserInfo) {
        this.formInline.boothNum = this.boothCode || "";
        this.formInline.comName = this.UserInfo.company || "";
        this.formInline.person = this.UserInfo.name || "";
        this.formInline.tel = this.UserInfo.phone || "";
      } else {
        this.formInline = {};
        this.formInline.boothNum = this.boothCode || "";
      }
    },
    // 验证表单
    validForm(value) {
      return new Promise((resolve, reject) => {
        this.$refs[value].validate(valid => {
          resolve(valid);
        });
      });
    },
    async submit(value) {
      // let isAvailable = [];
      // if (this.boothCodeInfo && this.boothCodeInfo.length > 0) {
      //   this.boothCodeInfo.map(c => {
      //     if (this.formInline.boothNum === c.BoothCode) {
      //       isAvailable.push(c);
      //     }
      //   });
      // }
      // if (isAvailable.length === 0) {
      //   this.flag = 1;
      // } else if (isAvailable[0].List && isAvailable[0].List.length > 0) {
      //   this.flag = 2;
      // } else {
      //   this.flag = 0;
      // }
      if (this.boothCode) {
        this.flag = 0;
      }
      let valid = await this.validForm(value);
      if (!valid) return;
      // let req = {
      //   UserId: this.UserInfo.Id,
      //   HostId: this.EntranceInfo.info.HostId,
      //   ExhibitionId: this.EntranceInfo.info.Id,
      //   Name: this.formInline.person,
      //   Hall: this.formInline.Hall,
      //   Boothcode: this.formInline.boothNum,
      //   CompName: this.formInline.comName,
      //   Phone: this.formInline.tel,
      //   Introduction: this.formInline.content
      // }; 
      let body = {
          userId:this.UserInfo.id,
          hostId:this.exhibitionInfo.hostId,
          exhibitionId:this.exhibitionInfo.id,
          hall:'',
          boothCode:this.formInline.boothNum,
          name:this.formInline.person,
          compName:this.formInline.comName,
          phone:this.formInline.tel,
          introduction:this.formInline.content,
      };
      let mutation = `
            mutation ($boothClaim: BoothClaimCreate!) {
              boothClaim{
                  create(boothClaim: $boothClaim) {
                      code
                      message
                      result
                  }
              }
            }`;
      let opt = {
            body:{
              query: mutation,
              variables: {
                  boothClaim:body,
              },
            },
            token:this.token,
      };
      let result = await this.graphqlPostByTu(opt);
      console.log(result,'---')
      if (result.data.boothClaim.create.result) {
        this.$Message.success('提交成功');
        this.showModal = false;
        this.successModal = true;
      } else {
        this.$Message.success(result.data.boothClaim.create.message);
      }
    },
    closeSuc() {
      this.successModal = false;
      this.close();
    }
  },
  created() {
    if (this.boothCode) this.formInline.boothNum = this.boothCode;
    this.init();
  }
};
</script>

<style lang="scss">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
  }
  .ivu-select-item {
    padding: 7px 10px;
    font-size: 14px !important;
  }
}
.boothclaim {
  .ivu-modal-body {
    padding-bottom: 0;
  }
  .halls-boothclaim-content {
    .ivu-form .ivu-form-item-label {
      font-size: 14px;
    }
    .ivu-input {
      font-size: 14px;
    }
    .ivu-form-item-content {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.halls-boothclaim-content {
  padding: 10px 30px 2px 0;
  .code-list {
    max-height: 300px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 500;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  }
}
.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: auto;
    margin: 20px 0;
  }
  p {
    color: #0290fe;
    font-size: 20px;
  }
  span {
    margin: 25px 0 30px 0;
    font-size: 16px;
  }
}
</style>