<template>
  <div>
    <div class="sreach-new">
      <div class="sreach2-input">
        <div class="sreach2-logo">
          <img :src="exhibitionInfo.logo" v-if="exhibitionInfo" @click="linkToEx" />
        </div>
        <div class="sreach2-ipt ">
          <input autocomplete="off" type="text" class="sreach-input" v-model="iptVal" @input="iptChange" @blur="iptBlur" @focus="iptFocus" id="txtSreach" @keyup.enter="submit" v-focus="isFocus" placeholder="搜展区/展馆/展位" />
        </div>
        <span class="input-close" id="iptClose" title="清空" @click="clearIpt">
          <i class="icon iconfont icon-closec" v-if="showiptClose"></i>
        </span>
        <div class="sreach2-icon">
          <i class="icon iconfont icon-search"></i>
        </div>
      </div>
      <div class="sreach2-addr">
        <Dropdown trigger="click" placement="bottom-start" @on-visible-change="changeDown()">
          <div class="all">
            <template v-if="!classVals.length">
              全部
            </template>
            <template v-else>
              <Badge :count="classVals.length">
                地址
              </Badge>
            </template>
            <Icon type="ios-arrow-down" :class="classVals.length ? 'act-icon' : ''"></Icon>
          </div>
          <DropdownMenu slot="list">
            <div class="addr-content">
              <p @click="emptyChange">全部地区</p>
              <div class="addr-list" v-for="(item, index) in areaOption" :key="index">
                <h3>{{ item.name }}</h3>
                <!-- <span @click="selectAddr('all')" :class="{is_active:isClassAll} ">全部</span> -->
                <CheckboxGroup v-model="classVals" @on-change="classChange">
                  <!-- <Checkbox class="list-check"
                            :label="'国际'" 
                            v-if="item.name == '国际'"
                            >
                        <span>全部</span>
                        </Checkbox>
                        <Checkbox class="list-check"
                            :label="'中国大陆'" 
                            v-if="item.name == '中国大陆'"
                            >
                        <span>全部</span>
                        </Checkbox>
                        <Checkbox class="list-check"
                            :label="'港澳台'" 
                            v-if="item.name == '港澳台'"
                            >
                        <span>全部</span>
                        </Checkbox> -->
                  <Checkbox v-for="(c, index) in item.lists" :key="'c' + index" :label="c.name" class="list-check">
                    <span>{{ c.name }}</span>
                  </Checkbox>
                </CheckboxGroup>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
      <!-- <div class="sreach-logo">
                <img :src="exhibitionInfo.logo"  v-if="exhibitionInfo"  @click="linkToEx">
            </div> -->
      <!-- <div id="input-hide" v-if="showIpt">
                    <div class="sreach-select">
                        <span class="find_ZS" @click="findType('zs')" :class="findTabType == 'zs' ? 'find_active' : ''">找展商</span>
                        <i class="fg">|</i>
                        <span class="find_T"  @click="findType('pro')" :class="findTabType == 'pro' ? 'find_active' : ''">
                            找展品
                        </span>
                        <i class="fg">|</i>
                        <span class="find_H"  @click="findType('hall')" :class="findTabType == 'hall' ? 'find_active' : ''">
                            找展区
                        </span>
                        <i class="fg">|</i>
                        <span class="find_Act" @click="findType('act')" :class="findTabType == 'act' ? 'find_active' : ''">找活动</span>
                        <i class="fg">|</i>
                        <i class="icon iconfont icon-search" id="sreach-switch" @click="goSearch"></i>
                    </div>
            </div> -->

      <!-- <div id="input-show" v-if="!showIpt">
                <div class="sreach-ipt">
                    <span class="input-search">
                        <i class="icon iconfont icon-search" @click="btnSreach"></i>
                    </span>
                    <input autocomplete="off" type="text" class="sreach-input" v-model="iptVal" 
                    @input="iptChange" 
                    @blur="iptBlur" 
                    @focus="iptFocus" 
                    id="txtSreach" 
                    @keyup.enter="submit"
                    v-focus='isFocus'
                    placeholder="展位号，公司名，品牌" />
                    <span class="input-close" id="iptClose" title="清空" @click="clearIpt">
                        <i class="icon iconfont icon-closec" v-if="showiptClose"></i>
                    </span>
                    <span class="comeBack" @click="comeBack">取消</span>
                </div>
            </div> -->
    </div>

    <div class="zsx-list">
      <!-- <div class="zsx-msg">
                <img src="@/assets/img/hall/my-icon_bell.png">
                <p>您是第{{ViewCount}}个访问者</p>
                <span @click="open" v-if="isopen" class="open"><i class="icon iconfont icon-xiala"></i>收起</span>
                <span @click="close" v-if="!isopen" class="close"><i class="icon iconfont icon-xiala"></i>展开</span>
            </div> -->
      <div v-if="showPd2">
        <!-- 找展商 展商排行榜 -->
        <div class="ranking" v-if="showrank">
          <div :style="{ height: maxHeight + 'px' }">
            <Scroll :on-reach-bottom="handleReachBottom" :height="maxHeight" :distance-to-edge="distance" :loading-text="loadingText" v-if="rankingData.length">
              <div class="rc" v-for="(item, index) in rankingData" :key="index">
                <span class="hot" v-if="item.isPopularize">热榜</span>
                <div class="rc-row s-left">
                  <div class="rc-row-box 333" @click="drawMap(item)">
                    <div class="rc-item">
                      <div class="rc-img">
                        <img v-if="item.company&&item.company.logo" :src="item.company.logo" />
                        <img v-else :src="exhibitionInfo.logo" />
                      </div>
                      <div class="rc-test">
                        <div class="title">
                          <p class="rc-test-title" :title="item.company.name" :class="item.continuouNumber ? '' : 'maxTitle'">{{ item.company.name }}</p>
                          <div class="type" v-if="item.continuouNumber">
                            <div class="img">
                              <img src="@/assets/img/jinpai.png" v-if="item.continuouNumber > 7" />
                              <img src="@/assets/img/tongpai.png" v-else-if="item.continuouNumber < 5" />
                              <img src="@/assets/img/yinpai.png" v-else />
                            </div>
                            <span>连续{{ item.continuouNumber }}年参展</span>
                          </div>
                        </div>
                        <div class="rc-test-booth" v-if="item.boothInfo || item.category">
                          <div class="zs-tag zs-booth" v-if="item.boothInfo">
                            <i class="iconfont icon-dizhi"></i>
                            <p>{{ item.boothInfo }}</p>
                          </div>
                          <div class="zs-tag zs-type" v-if="item.category">
                            <i class="iconfont icon-biaoqian1"></i>
                            <p>{{ item.category }}</p>
                          </div>
                        </div>
                        <div class="rc-test-main z-padd">
                          <p>
                            共发布：<em>{{ item.productNumber }}</em> 产品 <em>{{ item.activityNumber }}</em> 活动
                          </p>
                          <div class="zs-item-zan" v-if="item.likes && item.likes.length">
                            <template v-for="(item, index) in item.likes">
                              <img :src="JSON.parse(item.userInfo).avatarUrl" v-if="JSON.parse(item.userInfo).avatarUrl" :key="index" />
                              <img src="@/assets/img/user.png" v-else :key="index" />
                            </template>
                            <p>{{ item.likeCount }}人点赞</p>
                          </div>
                        </div>
                        <div class="rc-test-line" v-if="item.newestEvaluates[0]">最新评价：{{ item.newestEvaluates[0].content }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll>
            <div class="no-rc" v-if="!rankingData.length && !noCopBooth" style="text-align: center;padding:20px;color:#999">
              {{ searchLoading ? "正在搜索..." : "未查询到相关数据!" }}
            </div>
            <div class="no-com-booth" v-if="!rankingData.length && noCopBooth">该展位未绑定展商，<span @click="drawNoCop">点击查看</span></div>
          </div>
        </div>
        <!-- <findAct v-if="showActList"  @isLogin="isLogin"></findAct> -->
      </div>
    </div>

    <!-- 搜索下拉框 -->
    <div id="pull-down" :style="{ maxHeight: maxHeight + 'px' }">
      <!-- 大类 -->
      <div class="pd1" id="PD1" v-if="showPd1 && (brand.length || storageArr.length)">
        <div class="pd1-brands" v-if="brand.length">
          <div class="brands-title">推荐</div>
          <div class="brands-list">
            <div class="brands-one" v-for="(item, index) in brand" :key="index" @click="findBrand(item)">
              <img :src="item.logo" alt v-if="item.logo" style="width: 80px" />
              <img :src="exhibitionInfo.logo" alt v-else-if="exhibitionInfo&&exhibitionInfo.logo" style="width: 80px" />
              <img src="@/assets/img/empty.png" alt v-else style="width: 80px" />
              <span>{{ item.shortName || item.name }}</span>
            </div>
          </div>
        </div>
        <!-- 历史记录 -->
        <div class="pd1-history" v-if="storageArr.length">
          <p v-for="(item, index) in storageArr" :key="index" @click="selcetHistory(item)">
            <i class="icon iconfont icon-lishiguiji"></i>
            <span>{{ item.val }}</span>
          </p>
          <div class="clear-history" @click="clearHistory">清空记录</div>
        </div>
      </div>
    </div>
    <!-- 公司信息 -->
    <div class="pd3" id="PD3" v-if="showPd3 && CompMag">
      <comDetail :CompMag="CompDetalisMsg" @openLogin="openLogin" @openBigImg="openBigImg" @closePd3="closePd3"></comDetail>
    </div>
  </div>
</template>
<script>
import findAct from "./find-Act.vue";
import comDetail from "./comDetail.vue";
import regionData from "@/assets/js/region.json"; //地区json
import { debounce } from "@/assets/js/tool.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "search-tool",
  props: ["CompMag", "selectHall", "HallList"],
  data () {
    return {
      rankingData: [],
      PageSize: 20,
      PageIndex: 1,
      Total: 0,
      loadingText: "正在加载，请稍后",
      isopen: false,
      HallListData: [],
      brand: [],
      categoryJSON: null,
      options: [], // 搜索的条件

      showIpt: true, // 搜素框切换；
      showiptClose: false, // 搜索内容清除icon
      showrank: true, // 展商下拉列表
      showProList: false, // 展品下拉列表
      showHallList: false, // 展馆下拉列表
      findTabType: "zs", // 默认选择展商
      showActList: false, // 显示活动列表
      searchVal: null, // 搜索的值
      iptVal: null, // 输入框显示的值
      showPd1: false, // 推荐品牌下拉框
      showPd2: true, // 展商、展品、活动等下拉框
      showPd3: false, // 公司详细信息下拉框
      storageArr: [], // 保存历史记录
      ExBaseMsg: null, // 展会的基本信息
      CompDetalisMsg: null, //  选中公司的信息；
      ViewCount: null, // 参观的数据
      rankAddr: ["全部", "北京", "上海", "广州", "杭州", "重庆", "长沙", "成都"], // 城市
      tabindex: 0,
      showTab: true,
      searchLoading: true,
      isFocus: true,
      scrollMaxH: 0,
      distance: -2, // 如果是负的，回调将在到达边缘之前触发 无线滚动
      noCopBooth: null,

      areaOption: [],
      classVals: [],
      searchTxt: "", // 搜索的值
      finished: false,
      searchAddr: [],
      addrOpen: false,
      options2: {
        categories: [],
        regions: "",
        country: "",
        province: "",
        city: "",
        county: "",
      },
      isCheck1: false,
      isCheck2: false,
      isCheck3: false,
    };
  },
  components: {
    findAct,
    comDetail,
  },
  directives: {
    focus: {
      inserted: function (el, { value }) {
        if (value) {
          el.focus();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
    }),
    // categoryAndHallSplit() { // 展馆和展位号连接符
    //     let splitStr = this.$service.isSystemOpt(this, "categoryAndHallSplit");
    //     return splitStr ? splitStr : '';
    // },
    // exhibitionId(){
    //     return this.$route.query.exhibitionId;
    // },
    maxHeight () {
      return window.innerHeight - 400;
    },
    ...mapGetters({
      login: "getUser", // 是否登录
    }),
    // hostInfo() {
    //     return this.$store.getters.getEntranceInfo;
    // },
    // hostLogo() {
    //     let logo = "";
    //     if (this.hostInfo && this.hostInfo.info) {
    //         logo = this.hostInfo.info.Logo || this.hostInfo.host.Logo;
    //     }
    //     return logo;
    // },
  },
  watch: {
    CompMag (val) {
      if (val) {
        this.CompDetalisMsg = this.CompMag; // 当前展位公司信息
        this.showPd3 = true;
        this.showPd2 = false;
        this.showPd1 = false;
        // if(!this.isopen){
        //     this.isopen = true;
        // }
        this.findTabType = "";
      }
    },
    selectHall (val) {
      // 点击选中的公司
      // console.log(val,'点击选中的公司')
      if (val && !this.showIpt) {
        this.showIpt = true;
      }
    },
  },
  created () {
    // this.initRank();
    this.initData();
    this.areaOption = this.getRegionList(regionData);
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      unverifiedDialog: "unverifiedDialog",
    }),
    // 工具类(遍历地区)
    //获取数组  传一个数组json 遍历其下 id 于 name isLists 是否获取其list
    getRegionList (arrJson, isLists = true) {
      let classArr = [];
      if (isLists) {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            nameEn: el.nameEn,
            icon: el.icon,
            lists: this.getList(el.lists, false),
          });
        });
      } else {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            nameEn: el.nameEn,
            icon: el.icon,
          });
        });
      }
      return classArr;
    },
    // 工具类(遍历类别)
    //获取数组  传一个数组json 遍历其下 id 于 name isLists 是否获取其list
    getList (arrJson, isLists = true) {
      let classArr = [];
      if (isLists) {
        if (arrJson) {
          arrJson.forEach((el) => {
            const en = el.nameEn || "";
            classArr.push({
              id: el.id,
              name: el.name.length > 4 ? el.name.substring(0, 3) + "..." : el.name,
              nameEn: en.length > 10 ? en.substring(0, 9) + "..." : en,
              icon: el.icon,
              lists: this.getList(el.childrens, false),
            });
          });
        }
      } else {
        if (arrJson) {
          arrJson.forEach((el) => {
            classArr.push({
              id: el.id,
              name: el.name,
              nameEn: el.nameEn,
              icon: el.icon,
            });
          });
        }
      }
      return classArr;
    },

    classChange () {
      console.log(this.classVals, "classVals");
      // if(this.classVals.findIndex('国际'))
      // let res = this.classVals.some((c)=>{
      //     return c == '国际'
      // });
      // let res2 = this.classVals.some((c)=>{
      //     return c == '中国大陆'
      // });
      // let res3 = this.classVals.some((c)=>{
      //     return c == '港澳台'
      // })
      // console.log(res,res2,res3,this.isCheck1,' ---')
      // if(res) {
      //     let check1 = ["亚洲", "欧洲", "北美洲", "南美洲", "大洋洲", "非洲"]
      //     this.classVals = [...this.classVals,...check1];
      // }
      // if(res2) {
      //     let check2 =  ["华东地区", "华北地区", "华南地区", "华中地区", "东北地区", "西北地区", "西南地区"]
      // }
      // if(res3) {
      //     let check3 =  ["台湾", "香港", "澳门"]
      // }
      if (this.classVals.length) {
        this.options2.regions = this.classVals.join(";");
        if (this.options2.regions) {
          this.PageIndex = 1;
          this.rankingData = [];
          this.showrank = true;
          this.showPd2 = true;
          this.showPd1 = false;
          this.showPd3 = false;
          this.showiptClose = true;
          // this.iptVal = this.options2.regions;
          this.initRank();
        }
      }
    },
    emptyChange () {
      this.classVals = [];
      this.PageIndex = 1;
      this.rankingData = [];
      this.showrank = true;
      this.showPd2 = true;
      this.showPd1 = false;
      this.showPd3 = false;
      this.showiptClose = true;
      this.searchTxt = "";
      this.options2 = {
        categories: [],
        regions: "",
        country: "",
        province: "",
        city: "",
        county: "",
      };
      this.initRank();
    },
    changeDown () {
      this.addrOpen = !this.addrOpen;
      if (this.addrOpen) {
        // this.classVals = [];
        this.iptVal = "";
      }
      // if(!this.addrOpen) {
      //     this.options2.regions = this.classVals.join(";");
      //     if(this.options2.regions){
      //         this.PageIndex = 1;
      //         this.rankingData = [];
      //         this.showrank = true;
      //         this.showPd2 = true;
      //         this.showPd1 = false;
      //         this.showPd3 = false;
      //         this.showiptClose = true;
      //         this.iptVal = this.options2.regions;
      //         this.initRank();
      //     }
      // }
      console.log(this.addrOpen, "关闭");
      console.log(this.classVals, this.options.regions, "classVals");
    },

    linkToEx () {
      var routeData1 = this.$router.resolve({
        name: "default",
        params: { id: this.exhibitionId },
      });
      window.open(routeData1.href, "_blank");
    },
    initRank () {
      this.showrank = true;
      this.searchLoading = true;
      this.loadingText = "正在加载，请稍后";
      this.noCopBooth = null;
      this.getExList(); // 获取搜索列表
    },
    //获取数据
    async getExList () {
      this.loading = true;
      let query = `#graphql
          query($query:QueryExhibitorInput!){
              exhibitorQuery{
                  queryCustom(query:$query){
                      hasNextPage
                      pageIndex
                      totalCount
                      items {
                      activities(num:3){
                          id
                          title
                      }
                      banner
                      boothArea
                      boothInfo
                      businesses(num:3){
                          id
                          remark
                          isSample
                      }
                      category
                      company{
                          id
                          logo
                          name
                          category
                          address
                      }
                      compId
                      exhibitionId
                      followCount
                      id
                      isPopularize
                      isLiving
                      likeCount
                      lookCount
                      proCount
                      activityCount
                      activityNumber
                      productNumber
                      liveCount
                      continuouNumber
                      likes{
                          userInfo
                      }
                      newestEvaluates{
                          id
                          content
                      }
                      products(num:10){
                          id
                          exhibitorId
                          banner
                          view3DUrl
                      }
                      shareCount
                      talkCount
                      thinkCount
                      userCount
                      viewCount
                      }
                  }
                }
              }
            `;
      let opt = {
        query: query,
        variables: {
          query: {
            text: this.searchTxt,
            exhibitionId: this.exhibitionId,
            page: this.PageIndex,
            size: this.PageSize,
            ...this.options2,
          },
        },
      };
      let res = await this.graphqlPost(opt);
      let data = JSON.parse(JSON.stringify(res.data.exhibitorQuery));
      if (data && data.queryCustom) {
        let currentList = data.queryCustom.items;
        currentList.map((c) => {
          if (c.category && c.category.indexOf("[") !== -1) {
            if (JSON.parse(c.category)[0]) {
              c.category = JSON.parse(c.category)[0].Value;
            } else {
              c.category = "";
            }
          }
        });
        if (this.pageIndex == 1) {
          this.rankingData = currentList;
        } else {
          this.rankingData = [...this.rankingData, ...currentList];
        }
        console.log(this.rankingData, "this.rankingData");
        if (!data.queryCustom.hasNextPage) {
          this.finished = true;
        }
        // 搜索列表
        this.searchLoading = false;
        if (this.iptVal) {
          var value = this.iptVal.trim(); // 搜索结果
          if (value && this.rankingData.length == 0) {
            // 排行榜数据无搜索结果
            this.HallList.map((c) => {
              c.shapes.map((v) => {
                if (v.boothCode && v.boothCode.toUpperCase() == value.toUpperCase()) {
                  this.noCopBooth = v;
                  console.log(this.noCopBooth, "没有绑定公司的展位搜索");
                }
              });
            });
          }
        }
      }
      // if (this.$refs.exhibitorList) {
      //     this.$refs.exhibitorList.setData(this.dataList);
      // }
    },
    selectTab (value, index) {
      // 按地点搜索
      this.tabindex = index;
      if (value == "全部") {
        this.options = [];
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
      } else {
        let conditions = [
          { Label: "CompName", type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "Customer.ShortName", Type: "like", Value: "%" + value + "%" },
        ];
        this.options = conditions;
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
      }
    },
    initData () {
      // this.getCategory();
      // this.getExMsg();
      // this.getHallList();
      // this.getStatistics();
      this.getRecommend();
    },
    // 获取展会的基本信息
    getExMsg () {
      this.$http.get(`/api/exhibition/get/${this.exhibitionId}`).then((res) => {
        if (res.data.StatusCode === 200 && res.data.Data) {
          this.ExBaseMsg = res.data.Data;
          localStorage.setItem("ExBaseMsg", JSON.stringify(this.ExBaseMsg));
          document.title = `${this.ExBaseMsg.info.Name}`;
        }
      });
    },
    getCategory () {
      // 获取展品类别
      this.$http.get(`/api/compinfocategory/by?itemId=${this.exhibitionId}`).then((res) => {
        if (res.data.StatusCode === 200 && res.data.Data) {
          this.categoryJSON = res.data.Data;
        }
      });
    },
    getHallList () {
      // 获取展馆列表
      this.$http.get(`api/exhibitionhall/by?itemId=${this.exhibitionId}`).then((res) => {
        if (res.data.StatusCode === 200 && res.data.Data) {
          this.HallListData = res.data.Data;
          // console.log(this.HallListData);
        }
      });
    },
    getStatistics () {
      // 获取展会统计信息
      this.$http.get(`/api/exhibition/notice?itemid=${this.exhibitionId}`, { headers: { "api-version": 2 } }).then(({ data: { StatusCode, Data } }) => {
        if (StatusCode === 200) {
          this.ViewCount = Data.ViewCount;
        }
      });
    },
    async getRecommend () {
      // 获取推荐的展商
      let query = `#graphql
        query($input: GetExhibitorTopByLogoInput!){
            exhibitorQuery{
            topByLogo(input:$input){
                exhibitorId
                logo
                name
                nameEn
                shortName
                shortNameEn
            }
            }
        }`;
      let body = {
        query: query,
        variables: {
          input: {
            exhibitionId: this.exhibitionId,
            num: 4,
          },
        },
      };
      let res = await this.graphqlPost(body);
      if (res.data.exhibitorQuery.topByLogo) {
        let data = JSON.parse(JSON.stringify(res.data.exhibitorQuery.topByLogo));
        this.brand = data;
      }
    },

    // getRecommend(){ // 获取推荐的展商
    //     this.$http.get(`/api/compinfo/GetRecommend?itemId=${this.exhibitionId}&num=4`, {headers: {'api-version':2}})
    //               .then(({data: {StatusCode, Data}}) => {
    //                 if (StatusCode === 200 && Data.length) {
    //                     // this.ViewCount = Data.ViewCount;
    //                     // console.log(Data);
    //                     this.brand = Data;
    //                 }
    //               });
    // },

    // 加载更多
    handleReachBottom () {
      var that = this;
      this.PageIndex += 1;
      if (this.finished) {
        this.loadingText = "没有更多";
        return;
      }
      this.initRank();
    },
    // 点击展商，绘制展位图
    drawMap (item) {
      console.log(item, "选中的公司");
      if (!this.login) {
        this.unverifiedDialog(this);
        return;
      }
      if (!this.iptVal) {
        this.iptVal = item.company.name;
      }
      this.$emit("addhistory", this.iptVal);
      this.$emit("drawCom", { item: item, searVal: this.iptVal });
    },
    drawNoCop () {
      this.$emit("drawNoCom", { item: this.noCopBooth, searVal: this.iptVal });
      this.$emit("addhistory", this.iptVal);
    },
    // 根据展品类别获取展商排行榜
    async findPro (item) {
      const condition = [];
      if (item.Name !== "") {
        // 类别
        condition.push({
          Label: "Category",
          type: "like",
          Value: item.Name,
        });
      }
      this.PageIndex = 1;
      this.options = condition;
      this.rankingData = [];
      this.initRank();
      this.showrank = true;
      this.showProList = false;
    },

    // 根据展区获取展商排行榜
    async findHall (item) {
      const condition = [];
      if (item.Value !== "") {
        // 类别
        condition.push(
          {
            Label: "Hall",
            type: "like",
            Value: "%" + item.Value + "%",
          },
          {
            With: "or",
            Label: "BoothInfo",
            Type: "like",
            Value: "%" + item.Value + "%",
          } // 多展馆条件查询
        );
      }
      this.PageIndex = 1;
      this.options = condition;
      this.rankingData = [];
      this.initRank();
      this.showrank = true;
      this.showHallList = false;
    },
    // 展开收起下拉列表
    close () {
      this.isopen = true;
      this.showPd3 = !this.showPd3;
    },
    open () {
      this.isopen = false;
      this.showPd3 = !this.showPd3;
    },

    isLogin () {
      // 找活动的详情跳转
      this.$emit("isLogin");
    },

    // 找展区/展品
    findType (type) {
      this.tabindex = 0;
      this.isopen = true;
      this.showPd1 = false;
      this.showPd3 = false;
      this.showPd2 = true;
      // 删除选中公司信息；
      this.$emit("deteleCom");
      if (!this.login) {
        this.$Message.warning(`您还未登录，请先登录再进行操作`);
        this.$emit("isLogin");
        return;
      }
      switch (type) {
        case "zs": // 进入展商
          this.findTabType = "zs";
          this.showrank = true;
          this.showActList = false;
          this.showHallList = false;
          this.showProList = false;
          this.showTab = true;
          // 初始展商数据
          this.PageIndex = 1;
          this.options = [];
          this.rankingData = [];
          this.initRank();
          break;
        case "pro": // 进入展品
          this.showProList = true;
          this.showHallList = false;
          this.showActList = false;
          this.findTabType = "pro";
          this.showrank = false;
          this.showTab = false;
          break;
        case "hall": // 进入展馆
          this.showHallList = true;
          this.showProList = false;
          this.showActList = false;
          this.findTabType = "hall";
          this.showrank = false;
          this.showTab = false;
          break;
        case "act": // 进入展品
          this.findTabType = "act";
          this.showActList = true;
          this.showHallList = false;
          this.showProList = false;
          this.showrank = false;
          this.showTab = false;
          break;
        case "sear": // 进入搜索
          this.showrank = false;
          this.showActList = false;
          this.showHallList = false;
          this.showProList = false;
          this.showTab = false;
          this.iptVal = "";
          this.focusIpt();
          break;
      }
    },

    // 输入框获取焦点
    iptFocus () {
      this.focusIpt();
    },

    // 聚焦的输入框
    focusIpt () {
      this.showPd3 = false;
      this.$emit("deteleCom");
      this.showPd1 = true;
      this.showPd2 = false;
      // 初始化历史记录；
      this.storageArr = localStorage.getItem("search_save" + this.exhibitionId) ? JSON.parse(localStorage.getItem("search_save" + this.exhibitionId)) : [];
    },

    // 失去焦点
    iptBlur () {
      // console.log("失去焦点");
      // setTimeout(() => {
      //     this.showPd1 = false;
      // }, 200);
    },

    // 输入框直接搜索
    iptChange: debounce(function () {
      if (this.showIpt) {
        // 搜索界面
        this.iptSearch();
      }
    }, 500),

    iptSearch () {
      var value = this.iptVal.trim(); // 搜索结果
      console.log(value, "value 11111111111");
      this.showiptClose = false;
      this.showPd2 = false;
      this.$emit("deteleCom"); // 删除选中的展位
      if (value) {
        this.searchTxt = value;
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
        this.showPd2 = true;
        this.showPd1 = false;
        this.showPd3 = false;
        this.showiptClose = true;
      } else {
        // 没有值
        console.log("输入框没有值");
        this.showPd1 = true;
        this.showPd2 = false;
        this.showPd3 = false;
      }
    },

    // 点击搜索按钮搜索
    btnSreach () {
      this.iptSearch();
      var value = this.iptVal.trim(); // 搜索结果
      if (value) {
        this.$emit("addhistory", this.iptVal); // 添加历史记录
      }
    },

    // 键盘enter事件
    submit (e) {
      this.iptSearch();
      var value = this.iptVal.trim(); // 搜索结果
      if (value) {
        this.$emit("addhistory", this.iptVal); // 添加历史记录
      }
    },

    // 清除输入框 (并清除标记的展位);
    clearIpt () {
      this.iptVal = ""; // 清除输入框;
      this.showPd1 = false;
      this.showPd2 = false;
      this.showPd3 = false;
      this.showiptClose = false;
      this.$emit("deteleCom"); // 删除选中的展位
      this.iptFocus();
    },

    // 前去搜索输入框
    goSearch () {
      if (!this.login) {
        this.$Message.warning(`您还未登录，请先登录再进行操作`);
        this.$emit("isLogin");
        return;
      }
      this.showIpt = false;
      this.findType("sear"); // 初始为展商列表；
    },

    // 点击返回到默认选择tab
    comeBack () {
      this.showIpt = true;
      this.iptVal = "";
      this.showiptClose = false;
      this.showPd3 = false;
      this.showPd1 = false;
      this.initLeftList();
      this.$emit("deteleCom"); // 删除选中的展位
    },
    // 初始化展商列表
    initLeftList () {
      this.showPd2 = true;
      this.showTab = true;
      this.showrank = true;
      this.findTabType = "zs";
      this.PageIndex = 1;
      this.rankingData = [];
      this.options = [];
      this.initRank();
    },

    // 选择品牌
    findBrand (item) {
      console.log(item, "选择品牌");
      item.id = item.exhibitorId;
      this.iptVal = item.shortName || item.name;
      this.showiptClose = true;
      this.$emit("addhistory", this.iptVal);
      this.$emit("drawCom", { item: item, searVal: this.iptVal });
    },

    // 选择历史记录
    selcetHistory (item) {
      this.iptVal = item.val;
      this.showiptClose = true;
      this.iptSearch();
    },

    // 清除历史记录
    clearHistory () {
      this.storageArr = []; //清除历史记录
      localStorage.setItem("search_save" + this.exhibitionId, JSON.stringify(this.storageArr)); //清除历史记录
    },

    // 未登录点击贸易对接
    openLogin () {
      this.$emit("tradeClick");
    },

    // 展位认领
    openBoothClaim () {
      this.$emit("");
    },

    // 展商详情打开展位图全景图
    openBigImg (img) {
      this.$emit("openBigImg", img);
    },

    // 关闭搜索公司信息
    closePd3 () {
      if (!this.showIpt) {
        this.clearIpt();
      } else {
        this.showPd3 = false;
        this.$emit("deteleCom"); // 删除选中的展位
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.all {
  .ivu-badge {
    @include font_color(#1890ff);
  }
  .act-icon {
    @include font_color(#1890ff);
  }
  .ivu-badge-count {
    transform: scale(0.5);
  }
}
.open .icon {
  transform: rotate(180deg);
}
.fH-list {
  span {
    font-weight: 500;
    width: 100%;
  }
}
.fT-list {
  span {
    font-weight: 500;
  }
}
.pd3 {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 100px;
  width: 397px;
  margin: 0 14px 14px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 0;
  z-index: 1000;
}
.rank-addr {
  display: flex;
  justify-content: space-around;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #f5f5f5;
  span {
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
  .active {
    @include font_color(#1890ff);
  }
}
.rc-item-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
  @include font_color(#1890ff);
  min-height: 18px;
}
.no-com-booth {
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  padding: 20px 0;
  span {
    color: #1890ff;
  }
}

.sreach2 {
  &-input {
    display: flex;
    width: 397px;
    height: 46px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 5px;
    position: relative;
  }
  &-logo {
    width: 36px;
    height: 36px;
    margin-right: 6px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &-ipt {
    width: 250px;
    input {
      width: 100%;
      height: 36px;
      padding: 5px 10px;
      background: #fff;
    }
  }
  &-icon {
    width: 60px;
    position: absolute;
    right: 0;
    top: 7px;
    text-align: center;
    border-left: 1px solid #ededed;
    .icon {
      font-size: 30px;
      padding: 0;
      font-weight: 600;
      height: 36px;
      cursor: pointer;
      color: #999;
      line-height: 1;
      position: relative;
      top: 2px;
    }
  }
  &-addr {
    position: relative;
    z-index: 1002;
    width: 80px;
    height: 46px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
    .all {
      width: 80px;
      height: 46px;
      line-height: 46px;
      text-align: center;
    }
    .ivu-select-dropdown {
      padding: 0;
    }
    .addr {
      &-content {
        width: 306px;
        height: 332px;
        background-color: #fff;
        padding: 10px;
        p {
          width: 100%;
          height: 30px;
          background-color: #f7f7f7;
          border-radius: 5px;
          text-align: center;
          line-height: 30px;
          color: #333;
        }
        h3 {
          color: #666;
          margin-top: 12px;
          line-height: 16px;
          font-size: 14px;
          font-weight: normal;
        }
        span {
          display: inline-block;
          width: 64px;
          height: 30px;
          background-color: #f7f7f7;
          border-radius: 5px;
          cursor: pointer;
          text-align: center;
          line-height: 30px;
          margin: 10px 10px 0 0;
          font-size: 12px;
        }
        .list-check:nth-child(4n + 4) {
          span {
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
}
.ranking {
  margin-top: 0;
  .rc:hover {
    background-color: #f7f7f7;
  }
  .hot {
    position: absolute;
    right: -43px;
    top: -43px;
    width: 74px;
    height: 74px;
    display: inline-block;
    background-color: rgba(255, 94, 0, 0.15);
    color: #ff5e00;
    font-size: 12px;
    text-align: center;
    line-height: 130px;
    transform: rotate(45deg);
  }
  .rc {
    padding: 20px 0 20px 20px;
    position: relative;
    overflow: hidden;
    &-item {
      width: 100%;
    }
    &-row {
      padding: 0;
    }
    &-img {
      width: 96px;
      height: 96px;
      border-radius: 3px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }
    &-test {
      width: calc(100% - 96px) !important;
      .title {
        display: flex;
        p {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .maxTitle {
          max-width: 200px;
        }
        .type {
          display: flex;
          width: 100px;
          height: 20px;
          background-color: rgba(255, 94, 0, 0.15);
          border: solid 1px #ffe7d9;
          margin-left: 10px;
          .img {
            width: 20px;
            height: 100%;
            background: #fff;
            img {
              width: 12px;
              height: 14px;
              display: block;
              margin: 3px 4px 2px 4px;
            }
          }
          span {
            font-size: 12px;
            line-height: 20px;
            color: #ff5e00;
            display: inline-block;
            text-align: center;
            flex: 1;
          }
        }
      }
      &-booth {
        max-width: 95%;
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 20px;
        font-size: 14px;
        color: #666;
        margin-left: -2px;
        .zs-addr {
          font-size: 12px;
          flex-shrink: 0;
          margin-right: 20px;
        }
        .zs-tag {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          color: #333;
          .iconfont {
            color: #1890ff;
            transform: translateY(3px);
            margin-right: 2px;
            font-size: 14px;
          }
          .icon-biaoqian1 {
            position: relative;
            top: -2px;
          }
          .icon-dizhi {
            color: #fe781d;
            position: relative;
            top: -4px;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .zs-booth {
          margin-right: 15px;
        }
        .zs-type {
          overflow: hidden;
        }
      }
      &-main {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          em {
            font-style: normal;
            color: #ff5c00;
          }
        }
        .zs-item-zan {
          min-width: 100px;
          height: 22px;
          display: flex;
          margin-left: 10px;
          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            display: block;
            border: 1px solid #fff;
          }
          img:nth-child(1) {
            z-index: 13;
            position: relative;
          }
          img:nth-child(2) {
            z-index: 12;
            position: relative;
            margin-left: -10px;
          }
          img:nth-child(3) {
            z-index: 11;
            position: relative;
            margin-left: -10px;
          }
          p {
            min-width: 48px;
            height: 23px;
            color: #999;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 18px;
            margin-left: -10px;
            text-align: center;
            padding-right: 5px;
          }
        }
      }
      &-line {
        font-size: 12px;
        color: #666;
      }
    }
  }
}
</style>
