import { render, staticRenderFns } from "./theme-choice.vue?vue&type=template&id=33440582&scoped=true&"
import script from "./theme-choice.vue?vue&type=script&lang=js&"
export * from "./theme-choice.vue?vue&type=script&lang=js&"
import style0 from "./theme-choice.vue?vue&type=style&index=0&id=33440582&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33440582",
  null
  
)

export default component.exports