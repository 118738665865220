<template>
  <div>
    <div class="sreach-new">
      <div class="sreach-logo">
        <img :src="ExBaseMsg.info.Logo" v-if="ExBaseMsg" v-load="'comp_logo'" @click="linkToEx">
      </div>
      <div id="input-hide" v-if="showIpt">
        <div class="sreach-select">
          <span class="find_ZS" @click="findType('zs')" :class="findTabType == 'zs' ? 'find_active' : ''">找展商</span>
          <i class="fg">|</i>
          <span class="find_T" @click="findType('pro')" :class="findTabType == 'pro' ? 'find_active' : ''">
            找展品
          </span>
          <i class="fg">|</i>
          <span class="find_H" @click="findType('hall')" :class="findTabType == 'hall' ? 'find_active' : ''">
            找展区
          </span>
          <i class="fg">|</i>
          <span class="find_Act" @click="findType('act')" :class="findTabType == 'act' ? 'find_active' : ''">找活动</span>
          <i class="fg">|</i>
          <i class="icon iconfont icon-search" id="sreach-switch" @click="goSearch"></i>
        </div>
      </div>

      <div id="input-show" v-if="!showIpt">
        <div class="sreach-ipt">
          <span class="input-search">
            <i class="icon iconfont icon-search" @click="btnSreach"></i>
          </span>
          <input autocomplete="off" type="text" class="sreach-input" v-model="iptVal" @input="iptChange" @blur="iptBlur" @focus="iptFocus" id="txtSreach" @keyup.enter="submit" v-focus='isFocus' placeholder="展位号，公司名，品牌" />
          <span class="input-close" id="iptClose" title="清空" @click="clearIpt">
            <i class="icon iconfont icon-closec" v-if="showiptClose"></i>
          </span>
          <!-- <span class="comeBack"><i class="icon iconfont icon-guanbi" @click="comeBack"></i></span> -->
          <span class="comeBack" @click="comeBack">取消</span>
          <!-- <i class="icon iconfont icon-search btn-sreach" id="btnSreach" @click="btnSreach"></i> -->
        </div>
      </div>
    </div>

    <div class="zsx-list">
      <div class="zsx-msg">
        <img src="@/assets/img/hall/my-icon_bell.png">
        <p>您是第{{ViewCount}}个访问者</p>
        <span @click="open" v-if="isopen" class="open"><i class="icon iconfont icon-xiala"></i>收起</span>
        <span @click="close" v-if="!isopen" class="close"><i class="icon iconfont icon-xiala"></i>展开</span>
      </div>
      <div v-if="showPd2">
        <!-- 找展商 展商排行榜 -->
        <div class="ranking" v-if="showrank && isopen">
          <div class="rank-addr" v-if="showTab">
            <span v-for="(item,index) in rankAddr" :key="index" :class="{'active':index == tabindex}" @click="selectTab(item,index)">{{item}}</span>
          </div>
          <div :style="{ height: showTab ? maxHeight-48+'px' : maxHeight+'px'}">

            <Scroll :on-reach-bottom="handleReachBottom" :height="showTab ? maxHeight-48 : maxHeight" :distance-to-edge="distance" :loading-text="loadingText" v-if="rankingData.length">
              <div class="rc" v-for="(item,index) in rankingData" :key="index">
                <div class="rc-row s-left">
                  <div class="rc-row-box" @click="drawMap(item)">
                    <div class="rc-index">{{index+1}}</div>
                    <div class="rc-item">
                      <div class="rc-img-box">
                        <img v-if="item.Logo" :src="item.Logo" v-load="'comp_logo'" class="s-square">
                        <img v-if="!item.Logo" :src="hostLogo">
                      </div>
                      <div class="rc-test">
                        <p class="rc-test-title" :title="item.CompName">{{item.CompName}}</p>
                        <div class="rc-test-main">
                          <!-- <span class="rc-border" v-if="item.Category">{{item.Category}}</span>  -->
                          <span class="rc-border z-padd" v-if="item.BoothInfo && item.BoothInfo.length" style="border:none;">
                            <i class="icon iconfont icon-dot"></i>
                            <!-- <span>{{(item.Hall ? item.Hall + categoryAndHallSplit : '') + item.Booth}}</span> -->
                            <span v-if="item.BoothInfo && item.BoothInfo.length">
                              <span v-for="(booth, i) in item.BoothInfo" :key="'booth-' + i">
                                {{booth.Hall || ''}}{{ (booth.Hall && booth.Code) ? categoryAndHallSplit : ''}}{{booth.Code}}
                                <span v-if="(booth.Hall && booth.Code) && (i !== (item.BoothInfo.length - 1))">,</span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div class="rc-test-main z-padd">
                          <div class="brand">
                            <span>
                              <i class="icon iconfont icon-guanzhu"></i>
                              {{item.RegCount}}
                            </span>
                          </div>
                          <div class="brand">
                            <span>
                              <i class="icon iconfont icon-fenxiang"></i>
                              {{item.ShareCount}}
                            </span>
                          </div>
                          <div class="brand">
                            <span>
                              <i class="icon iconfont icon-dianzan"></i>
                              {{item.ZanCount}}
                            </span>
                          </div>
                          <div class="brand">
                            <span>
                              <i class="icon iconfont icon-zhiboguankanshu"></i>
                              {{item.ViewCount}}
                            </span>
                          </div>
                        </div>
                        <div class="rc-item-line" v-if="item.Category">
                          {{item.Category}}
                        </div>
                        <div class="rc-item-line" v-else></div>
                      </div>
                    </div>
                  </div>
                  <div class="rc-item-right" v-if="item.Products && item.Products.length">
                    <div class="rc-item-bottom">
                      <div class="rc-item-blist">
                        <div class="rc-item-blist_one" v-for="(pro, index) in item.Products" :key="'pro-' + index">
                          <div class="rc-item-blist_imgbox">
                            <img :src="JSON.parse(pro.Banner)[0]" v-load="'ex_proInfo'">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll>
            <div class="no-rc" v-if="!rankingData.length && !noCopBooth" style="text-align: center;padding:20px;">
              {{searchLoading ? '正在搜索...' : '暂无数据！'}}
            </div>
            <div class="no-com-booth" v-if="!rankingData.length && noCopBooth">
              该展位未绑定展商，<span @click="drawNoCop">点击查看</span>
            </div>
          </div>
        </div>

        <!-- 找展品 -->
        <div class="showfind_T" v-show="showProList && isopen">
          <div class="find-Type find-box" v-if="categoryJSON" :style="{ maxHeight: maxHeight+'px'}">
            <div class="fT-list" v-for="(itmes,index) in categoryJSON" :key="index" @click="findPro(itmes)">
              <span class="fT-name no-child">{{itmes.Name}}</span>
            </div>
          </div>
        </div>
        <!-- 找展区 -->
        <div class="showfind_H" v-show="showHallList && isopen">
          <div class="find-Hall find-box" :style="{ maxHeight: maxHeight+'px'}">
            <div class="fH-list" v-for="(item,index) in HallListData" :key="index" @click="findHall(item)">
              <span class="no-child">{{item.Value}}: {{item.Name}}</span>
            </div>
          </div>
        </div>
        <!-- <div class="showfind_H" v-show="showHallList && isopen">
                    <div class="find-Hall find-box" :style="{ maxHeight: maxHeight+'px'}">
                        <div class="fH-list" v-for="(item,index) in HallListData" :key="index" @click="findHall(item)">
                            <span :title="item.Value">{{item.Value}}</span>
                            <p :title="item.Name">{{item.Name}}</p>
                        </div>
                    </div>
                </div> -->
        <!-- 活动 -->
        <findAct v-if="showActList && isopen" @isLogin="isLogin"></findAct>
      </div>
    </div>

    <!-- 搜索下拉框 -->
    <div id="pull-down" :style="{ maxHeight: maxHeight+'px'}">
      <!-- 大类 -->
      <div class="pd1" id="PD1" v-if="showPd1 && (brand.length || storageArr.length) && isopen">
        <div class="pd1-brands" v-if="brand.length">
          <div class="brands-title">推荐</div>
          <div class="brands-list">
            <div class="brands-one" v-for="(item,index) in brand" :key="index" @click="findBrand(item)">
              <img :src="item.Logo" alt v-load="'comp_logo'" />
              <span>{{item.ShortName || item.CompName}}</span>
            </div>
          </div>
        </div>
        <!-- 历史记录 -->
        <div class="pd1-history" v-if="storageArr.length">
          <p v-for="(item,index) in storageArr" :key="index" @click="selcetHistory(item)">
            <i class="icon iconfont icon-lishiguiji"></i>
            <span>{{item.val}}</span>
          </p>
          <div class="clear-history" @click="clearHistory">清空记录</div>
        </div>
        <!-- <div class="pd1-mob">
                    <div class="mob-left">
                        <img src="@/assets/img/hall/code.png" alt />
                    </div>
                    <div class="mob-right">
                        <h3>微信扫一扫，加为好友</h3>
                        <p>解锁更多展位图的功能</p>
                    </div>
                </div> -->
        <!-- 点击消失层 -->
      </div>
    </div>
    <!-- 公司信息 -->
    <div class="pd3" id="PD3" v-if="showPd3 && CompMag">
      <comDetail :CompMag="CompDetalisMsg" @openLogin="openLogin" @openBigImg="openBigImg" @closePd3="closePd3"></comDetail>
    </div>

  </div>
</template>
<script>
import findAct from './find-Act.vue';
import comDetail from './comDetail.vue';
// import {debounce} from "../../../service/tool.js";
import { mapGetters } from "vuex";
export default {
  name: 'search-tool',
  props: ['CompMag', 'selectHall', 'HallList'],
  data () {
    return {
      rankingData: [],
      PageSize: 20,
      PageIndex: 1,
      Total: 0,
      loadingText: '正在加载，请稍后',
      isopen: false,
      HallListData: [],
      brand: [],
      categoryJSON: null,
      options: [], // 搜索的条件
      showIpt: true, // 搜素框切换；
      showiptClose: false, // 搜索内容清除icon
      showrank: true,     // 展商下拉列表
      showProList: false, // 展品下拉列表
      showHallList: false, // 展馆下拉列表
      findTabType: 'zs', // 默认选择展商
      showActList: false, // 显示活动列表
      searchVal: null, // 搜索的值
      iptVal: null, // 输入框显示的值
      showPd1: false, // 推荐品牌下拉框
      showPd2: true, // 展商、展品、活动等下拉框
      showPd3: false, // 公司详细信息下拉框
      storageArr: [], // 保存历史记录
      ExBaseMsg: null, // 展会的基本信息
      CompDetalisMsg: null, //  选中公司的信息；
      ViewCount: null, // 参观的数据
      rankAddr: ['全部', '北京', '上海', '广州', '杭州', '重庆', '长沙', '成都'], // 城市
      tabindex: 0,
      showTab: true,
      searchLoading: true,
      isFocus: true,
      scrollMaxH: 0,
      distance: -2, // 如果是负的，回调将在到达边缘之前触发 无线滚动
      noCopBooth: null,
    }
  },
  components: {
    findAct, comDetail,
  },
  directives: {
    focus: {
      inserted: function (el, { value }) {
        if (value) {
          el.focus();
        }
      }
    }
  },
  computed: {
    categoryAndHallSplit () { // 展馆和展位号连接符
      let splitStr = this.$service.isSystemOpt(this, "categoryAndHallSplit");
      return splitStr ? splitStr : '';
    },
    exhibitionId () {
      return this.$route.query.exhibitionId;
    },
    maxHeight () { return window.innerHeight - 250 },
    ...mapGetters({
      login: 'hall/getLogin',   // 是否登录
      exhibitionInfo: "getExhibitionInfo"
    }),
    hostInfo () {
      return this.$store.getters.getEntranceInfo;
    },
    hostLogo () {
      let logo = "";
      if (this.hostInfo && this.hostInfo.info) {
        logo = this.hostInfo.info.Logo || this.hostInfo.host.Logo;
      }
      return logo;
    },
  },
  watch: {
    CompMag (val) {
      if (val) {
        this.CompDetalisMsg = this.CompMag; // 当前展位公司信息
        this.showPd3 = true;
        this.showPd2 = false;
        this.showPd1 = false;
        if (!this.isopen) {
          this.isopen = true;
        }
        this.findTabType = '';
      }
    },
    selectHall (val) { // 点击选中的公司
      // console.log(val,'点击选中的公司')
      if (val && !this.showIpt) {
        this.showIpt = true;
      }
    }
  },
  created () {
    this.initRank();
    this.initData();
  },
  methods: {
    linkToEx () {
      var routeData1 = this.$router.resolve({
        name: "default",
        params: { id: this.exhibitionId }
      });
      window.open(routeData1.href, '_blank')
    },
    initRank () {
      this.searchLoading = true;
      this.loadingText = '正在加载，请稍后';
      this.noCopBooth = null;
      const reqBody = {
        By: this.exhibitionId,
        PageSize: this.PageSize,
        PageIndex: this.PageIndex,
        condition: [{
          Label: "compinfo.IsShow",
          type: "equal",
          Value: true,
        }, ...this.options]
      };
      this.$http.post("/api/compinfo/ranking2/wherepage", reqBody, { headers: { 'api-version': 2 } }).then(doc => {
        let state = doc.data;
        if (doc.data.StatusCode === 200 && state.Data) {
          state.Data.List = state.Data.List.map(c => {
            c.Products = c.Products.filter(v => v.IsShow);
            c.Activities = c.Activities.filter(v => v.IsShow);
            if (c.BoothInfo && c.BoothInfo.length) {
              let arr = JSON.parse(c.BoothInfo);
              // if (Array.isArray(arr)) {
              // arr = arr.filter(z => (z.Hall && z.Code));
              // }
              c.BoothInfo = arr;
            } else {
              c.BoothInfo = [];
            }
            return c;
          });
          this.rankingData = state.Data.List;
          this.Total = state.Data.Total;
          this.searchLoading = false;
          // 搜索列表
          if (this.iptVal) {
            var value = this.iptVal.trim(); // 搜索结果
            if (value && this.rankingData.length == 0) { // 排行榜数据无搜索结果
              this.HallList.map(c => {
                c.ShapeList.map((v) => {
                  if (v.BoothCode && v.BoothCode.toUpperCase() == value.toUpperCase()) {
                    this.noCopBooth = v
                    console.log(this.noCopBooth, '没有绑定公司的展位搜索')
                  }
                })
              })
            }
          }

        }
      });
    },
    selectTab (value, index) {  // 按地点搜索
      this.tabindex = index;
      if (value == '全部') {
        this.options = [];
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
      } else {
        let conditions = [
          { Label: "CompName", type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "Customer.ShortName", Type: "like", Value: "%" + value + "%" },
        ];
        this.options = conditions;
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
      }
    },
    initData () {
      this.getCategory();
      this.getExMsg();
      this.getHallList();
      this.getStatistics();
      this.getRecommend();
    },
    // 获取展会的基本信息
    getExMsg () {
      this.$http.get(`/api/exhibition/get/${this.exhibitionId}`)
        .then(res => {
          if (res.data.StatusCode === 200 && res.data.Data) {
            this.ExBaseMsg = res.data.Data
            localStorage.setItem('ExBaseMsg', JSON.stringify(this.ExBaseMsg));
            document.title = `${this.ExBaseMsg.info.Name}`
          }
        })
    },
    getCategory () { // 获取展品类别
      this.$http.get(`/api/compinfocategory/by?itemId=${this.exhibitionId}`)
        .then(res => {
          if (res.data.StatusCode === 200 && res.data.Data) {
            this.categoryJSON = res.data.Data;
          }
        })
    },
    getHallList () { // 获取展馆列表
      this.$http.get(`api/exhibitionhall/by?itemId=${this.exhibitionId}`)
        .then(res => {
          if (res.data.StatusCode === 200 && res.data.Data) {
            this.HallListData = res.data.Data;
            // console.log(this.HallListData);
          }
        })
    },
    getStatistics () { // 获取展会统计信息
      this.$http.get(`/api/exhibition/notice?itemid=${this.exhibitionId}`, { headers: { 'api-version': 2 } }).then(({ data: { StatusCode, Data } }) => {
        if (StatusCode === 200) {
          this.ViewCount = Data.ViewCount;
        }
      });
    },
    getRecommend () { // 获取推荐的展商
      this.$http.get(`/api/compinfo/GetRecommend?itemId=${this.exhibitionId}&num=4`, { headers: { 'api-version': 2 } })
        .then(({ data: { StatusCode, Data } }) => {
          if (StatusCode === 200 && Data.length) {
            // this.ViewCount = Data.ViewCount; 
            // console.log(Data);
            this.brand = Data;
          }
        });
    },
    handleReachBottom () {
      var that = this;
      this.PageIndex += 1;
      if (this.Total > this.rankingData.length) {
        return new Promise(resolve => {
          const reqBody = {
            By: this.exhibitionId,
            PageSize: this.PageSize,
            PageIndex: this.PageIndex,
            condition: [{
              Label: "compinfo.IsShow",
              type: "equal",
              Value: true,
            }, ...this.options]
          };
          this.$http.post("/api/compinfo/ranking2/wherepage", reqBody, { headers: { 'api-version': 2 } }).then(doc => {
            let state = doc.data;
            if (doc.data.StatusCode === 200 && state.Data) {
              state.Data.List = state.Data.List.map(c => {
                c.Products = c.Products.filter(v => v.IsShow);
                c.Activities = c.Activities.filter(v => v.IsShow);
                if (c.BoothInfo && c.BoothInfo.length) {
                  let arr = JSON.parse(c.BoothInfo);
                  if (Array.isArray(arr)) {
                    arr = arr.filter(z => (z.Hall && z.Code));
                  }
                  c.BoothInfo = arr;
                } else {
                  c.BoothInfo = [];
                }
                return c;
              });
              let copyData = [];
              if (that.rankingData && that.rankingData.length > 0) {
                copyData = that.rankingData;
              }
              // console.log(that.rankingData)
              that.rankingData = state.Data.List;
              that.rankingData = [...copyData, ...that.rankingData];
              resolve();
            }
          });
        });
      } else {
        this.loadingText = '没有更多';
      }
    },
    // 点击展商，绘制展位图
    drawMap (item) {
      console.log(item, '选中的公司')
      if (!this.login) {
        this.$Message.warning(`您还未登录，请先登录再进行操作`);
        this.$emit('isLogin')
        return;
      }
      if (!this.iptVal) {
        this.iptVal = item.ShortName || item.CompName;
      }
      this.$emit('addhistory', this.iptVal);
      this.$emit('drawCom', { item: item, searVal: this.iptVal });
    },
    drawNoCop () {
      this.$emit('drawNoCom', { item: this.noCopBooth, searVal: this.iptVal });
      this.$emit('addhistory', this.iptVal);
    },
    // 根据展品类别获取展商排行榜
    async findPro (item) {
      const condition = [];
      if (item.Name !== "") {
        // 类别
        condition.push({
          Label: "Category",
          type: "like",
          Value: item.Name
        });
      }
      this.PageIndex = 1;
      this.options = condition;
      this.rankingData = [];
      this.initRank();
      this.showrank = true;
      this.showProList = false;
    },

    // 根据展区获取展商排行榜
    async findHall (item) {
      const condition = [];
      if (item.Value !== "") {
        // 类别
        condition.push({
          Label: "Hall",
          type: "like",
          Value: "%" + item.Value + "%"
        }, {
          With: "or",
          Label: "BoothInfo",
          Type: "like",
          Value: "%" + item.Value + "%"
        },  // 多展馆条件查询
        );
      }
      this.PageIndex = 1;
      this.options = condition;
      this.rankingData = [];
      this.initRank();
      this.showrank = true;
      this.showHallList = false;
    },
    // 展开收起下拉列表
    close () {
      this.isopen = true;
      this.showPd3 = !this.showPd3;
    },
    open () {
      this.isopen = false;
      this.showPd3 = !this.showPd3;
    },

    isLogin () {  // 找活动的详情跳转
      this.$emit('isLogin');
    },

    // 找展区/展品
    findType (type) {
      this.tabindex = 0;
      this.isopen = true;
      this.showPd1 = false;
      this.showPd3 = false;
      this.showPd2 = true;
      // 删除选中公司信息；
      this.$emit('deteleCom');
      // if(type !="zs"){
      //     if (!this.login) {
      //         this.$Message.warning(`您还未登录，请先登录再进行操作`);
      //         this.$emit('isLogin')
      //         return;
      //     }
      // }
      switch (type) {
        case "zs": // 进入展商
          this.findTabType = 'zs';
          this.showrank = true;
          this.showActList = false;
          this.showHallList = false;
          this.showProList = false;
          this.showTab = true;
          // 初始展商数据
          this.PageIndex = 1;
          this.options = [];
          this.rankingData = [];
          this.initRank();
          break;
        case "pro": // 进入展品
          this.showProList = true;
          this.showHallList = false;
          this.showActList = false;
          this.findTabType = 'pro';
          this.showrank = false;
          this.showTab = false;
          break;
        case "hall": // 进入展馆
          this.showHallList = true;
          this.showProList = false;
          this.showActList = false;
          this.findTabType = 'hall';
          this.showrank = false;
          this.showTab = false;
          break;
        case "act": // 进入展品
          this.findTabType = 'act';
          this.showActList = true;
          this.showHallList = false;
          this.showProList = false;
          this.showrank = false;
          this.showTab = false;
          break;
        case "sear": // 进入搜索
          this.showrank = false;
          this.showActList = false;
          this.showHallList = false;
          this.showProList = false;
          this.showTab = false;
          this.iptVal = '';
          this.focusIpt();
          break;
      }
    },



    // 输入框获取焦点
    iptFocus () {
      this.focusIpt();
    },

    // 聚焦的输入框
    focusIpt () {
      this.showPd3 = false;
      this.$emit('deteleCom');
      this.showPd1 = true;
      this.showPd2 = false;
      // 初始化历史记录；
      this.storageArr = localStorage.getItem('search_save' + this.exhibitionId) ? JSON.parse(localStorage.getItem('search_save' + this.exhibitionId)) : [];
    },

    // 失去焦点
    iptBlur () {
      // console.log("失去焦点");
      // setTimeout(() => {
      //     this.showPd1 = false;
      // }, 200);
    },

    // 输入框直接搜索
    // iptChange: debounce(function(){
    //            if(!this.showIpt){ // 搜索界面
    //                this.iptSearch();
    //            }
    //     },500),

    iptSearch () {
      var value = this.iptVal.trim(); // 搜索结果
      this.showiptClose = false;
      this.showPd2 = false;
      this.$emit('deteleCom'); // 删除选中的展位
      if (value) {
        let conditions = [
          { Label: "CompName", type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "Customer.ShortName", Type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "Hall", Type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "Booth", Type: "like", Value: "%" + value + "%" },
          { With: "or", Label: "BoothInfo", Type: "like", Value: "%" + value + "%" },  // 多展馆条件查询
          { With: "or", Label: "Category", Type: "like", Value: "%" + value + "%" },
        ];
        this.options = conditions;
        this.PageIndex = 1;
        this.rankingData = [];
        this.initRank();
        this.showrank = true;
        this.showPd2 = true;
        this.showPd1 = false;
        this.showPd3 = false;
        this.showiptClose = true;
        // console.log(this.rankingData)
      } else {
        // 没有值
        console.log("输入框没有值");
        this.showPd1 = true;
        this.showPd2 = false;
        this.showPd3 = false;
      }
    },

    // 点击搜索按钮搜索
    btnSreach () {
      this.iptSearch();
      var value = this.iptVal.trim(); // 搜索结果
      if (value) {
        this.$emit('addhistory', this.iptVal); // 添加历史记录
      }
    },

    // 键盘enter事件
    submit (e) {
      this.iptSearch();
      var value = this.iptVal.trim(); // 搜索结果
      if (value) {
        this.$emit('addhistory', this.iptVal); // 添加历史记录
      }
    },

    // 清除输入框 (并清除标记的展位);
    clearIpt () {
      this.iptVal = ""; // 清除输入框;
      this.showPd1 = false;
      this.showPd2 = false;
      this.showPd3 = false;
      this.showiptClose = false;
      this.$emit('deteleCom'); // 删除选中的展位
      this.iptFocus();
    },

    // 前去搜索输入框
    goSearch () {
      if (!this.login) {
        this.$Message.warning(`您还未登录，请先登录再进行操作`);
        this.$emit('isLogin')
        return;
      }
      this.showIpt = false;
      this.findType('sear'); // 初始为展商列表；
    },

    // 点击返回到默认选择tab
    comeBack () {
      this.showIpt = true;
      this.iptVal = "";
      this.showiptClose = false;
      this.showPd3 = false;
      this.showPd1 = false;
      this.initLeftList();
      this.$emit('deteleCom'); // 删除选中的展位
    },
    // 初始化展商列表
    initLeftList () {
      this.showPd2 = true;
      this.showTab = true;
      this.showrank = true;
      this.findTabType = 'zs';
      this.PageIndex = 1;
      this.rankingData = [];
      this.options = [];
      this.initRank()
    },

    // 选择品牌
    findBrand (item) {
      console.log(item, '选择品牌');
      this.iptVal = item.ShortName || item.CompName;
      this.showiptClose = true;
      this.$emit('addhistory', this.iptVal);
      this.$emit('drawCom', { item: item, searVal: this.iptVal });
    },

    // 选择历史记录
    selcetHistory (item) {
      // console.log(item,'历史记录');
      this.iptVal = item.val;
      this.showiptClose = true;
      this.iptSearch();
    },

    // 清除历史记录
    clearHistory () {
      this.storageArr = []; //清除历史记录
      localStorage.setItem('search_save' + this.exhibitionId, JSON.stringify(this.storageArr)); //清除历史记录
    },

    // 未登录点击贸易对接
    openLogin () {
      this.$emit('tradeClick');
    },

    // 展位认领
    openBoothClaim () {
      this.$emit('');
    },

    // 展商详情打开展位图全景图
    openBigImg (img) {
      this.$emit('openBigImg', img)
    },

    // 关闭搜索公司信息
    closePd3 () {
      if (!this.showIpt) {
        this.clearIpt()
      } else {
        this.showPd3 = false;
        this.$emit('deteleCom'); // 删除选中的展位

      }
    }


  }

}
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.open .icon {
  transform: rotate(180deg);
}
.fH-list {
  // margin-bottom: 10px;
  span {
    font-weight: 500;
    width: 100%;
  }
}
.fT-list {
  span {
    font-weight: 500;
  }
}
.pd3 {
  position: absolute;
  left: 0;
  top: 166px;
  width: 397px;
  margin: 0 14px 14px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 0;
  z-index: 1000;
}
.rank-addr {
  display: flex;
  justify-content: space-around;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #f5f5f5;
  span {
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
  .active {
    @include font_color(#1890ff);
  }
}
.rc-item-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
  @include font_color(#1890ff);
  min-height: 18px;
}
.no-com-booth {
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  padding: 20px 0;
  span {
    color: #1890ff;
  }
}
</style>