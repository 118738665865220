<template>
  <div>
    <div class="pd3-cancle">
      <i
        @click="closePd3"
        class="icon iconfont icon-guanbi"
      ></i>
    </div>
    <div
      :style="{ maxHeight: maxHeight+'px'}"
      class="pd3-cop"
    >
      <!-- 公司名称 -->
      <div class="pd3-cop-title">
        <!-- 公司标题信息 -->
        <div class="pd3-cop-msg">
          <div
            @click="linkTo(CompMag,'com')"
            class="pd3-cop-l"
          >
            <div class="pd3-cop-logo">
              <img
                :src="companyData.company.logo"
                id="ComLogo"
                v-if="companyData && companyData.company.logo"
              />
              <img
                :src="exhibitionInfo && exhibitionInfo.logo ? exhibitionInfo.logo : 'http://m.zhanshangxiu.com/static/img/empty.04dde5d.png'"
                id="ComLogo"
                v-else
              />
            </div>
            <div class="pd3-cop-name">
              <div
                :title="CompMag.businessData[0] ? CompMag.businessData[0].exhibitor.name : '暂未绑定'"
                class="pd3-cop-h2"
              >{{CompMag.businessData[0] ? CompMag.businessData[0].exhibitor.name : '暂未绑定'}}</div>
              <div class="pd3-cop-p">
                <span
                  class="booth"
                  v-if="companyData && companyData.boothInfo && !CompMag.boothCode"
                >
                  <i class="icon iconfont icon-dizhi"></i>
                  {{companyData.boothInfo}}
                </span>
                <span
                  :class="CompMag.boothCode ? '' : 'cop-category-none'"
                  class="booth"
                  v-if="CompMag.boothCode"
                >
                  <i class="icon iconfont icon-dizhi"></i>
                  {{CompMag.boothCode}}
                </span>
                <span
                  :class="companyData && companyData.category ? '' : 'cop-category-none'"
                  class="category"
                >
                  <i class="icon iconfont icon-biaoqian1"></i>
                  {{companyData && companyData.category ? companyData.category : ''}}
                </span>
              </div>
              <div
                class="addr"
                v-if="companyData && companyData.company.address"
              >{{companyData.company.address}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 有公司信息的状态 -->
      <div
        class="hasComp"
        v-if="CompMag.businessData[0] && companyData"
      >
        <!-- 数据 -->
        <div class="pd3-msg">
          <div class="pd3-msg-li">
            <h4>{{companyData.talkCount}}</h4>
            <p>洽谈</p>
          </div>
          <div class="pd3-msg-li">
            <h4>{{companyData.appointmentNumber}}</h4>
            <p>预约</p>
          </div>
          <div class="pd3-msg-li">
            <h4>{{companyData.userCount}}</h4>
            <p>访客</p>
          </div>
          <div class="pd3-msg-li">
            <h4>{{companyData.productNumber}}</h4>
            <p>展品</p>
          </div>
          <div class="pd3-msg-li">
            <h4>{{companyData.activityNumber}}</h4>
            <p>活动</p>
          </div>
        </div>
        <!-- 简介 -->
        <div
          class="pd3-cop-intro"
          v-if="Introduction"
        >
          {{Introduction}}
          <span
            @click="moreIntroduction"
            class="pd3-more-intro"
            v-if="moreInt"
          >更多</span>
        </div>
        <!-- 展品图片 -->
        <div
          class="pd3-cop-act"
          v-if="productData && productData.length"
        >
          <h2 class="pd3-title">展品</h2>
          <div class="act-list">
            <div
              :key="index"
              @click="linkTo(item,'pro')"
              class="act-list-one"
              v-for="(item,index) in productData"
            >
              <div class="act-one-imgbox pro-one-imgbox">
                <img
                  :src="JSON.parse(item.banner)[0]"
                  alt
                  v-if="item.banner.search(/^\[/) !== -1"
                />
              </div>
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
        <!-- 活动图片 -->
        <div
          class="pd3-cop-act"
          v-if="activityData && activityData.length"
        >
          <h2 class="pd3-title">活动</h2>
          <div class="act-list">
            <div
              :key="index"
              @click="linkTo(item,'act')"
              class="act-list-one"
              v-for="(item,index) in activityData"
            >
              <div class="act-one-imgbox">
                <img
                  :src="JSON.parse(item.banners)[0]"
                  alt
                  v-if="item.banners.search(/^\[/) !== -1"
                />
              </div>
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
        <!-- 参观的游客 -->
        <div
          class="pd3-cop-view"
          style="margin-bottom:8px;"
          v-if="onlookers"
        >
          <h2
            class="pd3-title"
            style="padding:8px 0 12px 0"
          >
            &nbsp;&nbsp;参观游客
            <span>
              <i>共</i>
              {{FollowerCount}}
              <i>人</i>
            </span>
          </h2>
          <div class="pd3-cop-view-list">
            <span
              :key="index"
              v-for="(item,index) in onlookers"
            >
              <img
                :src="item.member.avatarUrl"
                v-if="item.member && item.member.avatarUrl"
              />
              <img
                src="@/assets/img/user.png"
                v-else
              />
            </span>
          </div>
        </div>
      </div>
      <!-- 没有公司信息的状态 -->
      <div
        class="noComp"
        v-else
      >
        <p>
          该展位暂未绑定展商
          <span
            @click="getRoleHandle('L1BMM-L2Claim-L3Claim') && boothClaimNow()"
            class="goLink"
          >马上认领</span>
        </p>
      </div>
    </div>
    <!-- 底部操作 -->
    <div
      class="pd3-cop-bot"
      v-if="CompMag.businessData[0]"
    >
      <span
        @click="mobView(CompMag.businessData[0])"
        class="bot-mob"
      >
        <i class="icon iconfont icon-erweima1"></i>
        移动端
      </span>
      <p class="bot-left">
        <span
          @click="addLove()"
          class="bot-love1"
          v-if="!hasCollect"
        >关注</span>
        <span
          @click="delLove()"
          class="bot-love2"
          v-else
        >已关注</span>
        <span @click="onLineTalk" class="bot-view"v-if="companyData&&companyData.customerServiceIsAppointmentNumber>0">
          <a href="javascript:;"style="color:#fe781d">在线洽谈</a>
        </span>
        <span class="bot-view" v-else style="border:1px solid #bfbfbf"><a href="javascript:;"style="color: #bfbfbf">在线洽谈</a>
        </span>
        <span
          @click="orderOpen"
          class="bot-view"
        >
          <a
            href="javascript:;"
            style="color:#fe781d"
          >预约</a>
        </span>
      </p>
    </div>
    <!-- 贸易对接弹窗 -->
    <!-- <Trade :showTrade="showTrade" @closeTrade="showTrade=false" :info="reqData" />  -->

    <!-- 二维码弹框 -->
    <Modal
      :footer-hide="true"
      :mask-closable="false"
      class-name="vertical-center-modal mobile myModel"
      title="移动端"
      v-model="showModal"
      width="480px"
    >
      <!-- 展商秀二维码 -->
      <div id="zsxCode">
        <div class="zsxCode-box">
          <h3>登录移动端查看</h3>
          <div
            class="code-img"
            id="qrcodeHall"
          ></div>
        </div>
      </div>
      <!-- <div slot="footer">
        <Button type="primary" @click="showModal = false;">确定</Button>
      </div>-->
    </Modal>

    <!--展位图弹框 -->
    <Modal
      :mask-closable="false"
      class-name="vertical-center-modal mobile myModel"
      footer-hide
      v-model="bannerModal"
      width="800"
    >
      <!-- 展商秀二维码 -->
      <swiper
        :options="swiperOption"
        ref="swiper"
        style="height:80%;width:90%;top:10%;left:0;bottom:0;right:0;margin:auto;"
      >
        <swiperSlide
          style="width:100%;height:100%;"
          v-if="boothMap && boothMap.Design"
        >
          <img
            :src="boothMap.Design"
            style="width:100%;height:500px"
            v-load="'watermark'"
          />
        </swiperSlide>
        <swiperSlide
          style="width:100%;height:100%;"
          v-if="boothMap && boothMap.Position"
        >
          <img
            :src="boothMap.Position"
            style="width:100%;height:500px"
            v-load="'watermark'"
          />
        </swiperSlide>
        <div
          @click="swiperPrev"
          class="swiper-button-prev"
          slot="button-prev"
        ></div>
        <div
          @click="swiperNext"
          class="swiper-button-next"
          slot="button-next"
        ></div>
      </swiper>
    </Modal>

    <!-- 展位认领弹窗 -->
    <boothClaim
      :boothClaimFlag="boothClaimFlag"
      :boothCode="CompMag.boothCode"
      @closeboothClaim="boothClaimFlag=false;"
    ></boothClaim>
    <chatTalkUser :exhibitorId="companyId" :isAlone="true" v-if="talkShow"></chatTalkUser>
    <!-- 预约洽谈 -->
    <orderModal
      :exhibitorId="companyId"
      ref="orderModal"
    ></orderModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import Trade from "./halls-trade/halls-trade.vue";
import QRCode from "qrcodejs2";
import boothClaim from "./halls-boothclaim/halls-boothclaim.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import orderModal from "@components/orderModal/index.vue";
import chatTalkUser from "@components/chat-talk-user/index.vue";
import url from "@/utils/url";
export default {
  name: "comDetail",
  props: ["CompMag"],
  data () {
    return {
      qrcode: null,
      compData: null,
      companyId: null,
      productData: null,
      activityData: null,
      companyData: null,
      banner: "",
      showTrade: false,
      showModal: false,
      FollowerCount: null, // 围观数
      onlookers: "",
      lookCount: 1,
      boothClaimFlag: false, // 展位认领
      hasCollect: false, // 是否收藏
      reqData: null, // 提交的option
      bannerModal: false,
      talkShow:false,
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      boothMap: null,
      Introduction: '',
      moreInt: false,
      zDisable: true, // 开展前1个月和展后一周开启
      isBoothClaim: false, // 展后2个月关闭
    };
  },
  components: {
    //   Trade,
    boothClaim,
    orderModal,
    chatTalkUser
  },
  created () {
    this.init();
  },
  mounted () {
    this.$Bus.$off('talkAloneClose')
    this.$Bus.$on('talkAloneClose',()=>{
    this.talkShow=false;
    })
    this.qrcode = new QRCode("qrcodeHall", {
      width: 200,
      height: 200 // 高度
    });
  },
  computed: {
    ...mapGetters({
      // login: "hall/getLogin",
      // header: "getHeader",
      userInfo: "getUser",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: 'getExhibitionId',
      exhibitorId:'getExhibitorId'
    }),
    isRegOk () {
      return (
        this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole &&
        this.userInfo.inMember.userRole != "VISITOR" &&
        this.userInfo.inMember.userRole != "Visitor"
      );
    },
    isServer(){
      let ser=false;
      if(this.userInfo&&this.userInfo.inMember&&this.userInfo.inMember.companyUsers&&this.userInfo.inMember.companyUsers.id){
        ser=true;
      }
      return ser
    },
    maxHeight () { return window.innerHeight - 400 },
  },
  watch: {
    CompMag (val) {
      console.log(this.CompMag, "选中展位的信息");
      this.companyData = null;
      this.productData = null;
      this.activityData = null;
      this.onlookers = "";
      this.Introduction = "";
      this.init();
    }
  },
  methods: {
    ...mapActions({
      // isCollect: "hall/isCollect",
      // addToCollect: "hall/addCollect",
      // addToTracking: "hall/addTracking",
      // delToCollect:"hall/delCollect",

      graphqlPost: "graphqlPostByZXS",
      addCompLove: "exhibitor/addCompLove",
      delCompLove: "exhibitor/delCompLove",
      unverifiedDialog: "unverifiedDialog",
      addView: "loginModule/addView",
      addAppointmentNumber: "appointment/addAppointmentNumber",

    }),
    async onLineTalk () {
      // console.log(this.CompMag);
      // if (this.CompMag && this.CompMag.businessData && this.CompMag.businessData[0] && this.CompMag.businessData[0].exhibitor) {
      //   this.addAppointmentNumber(this.CompMag.businessData[0].exhibitor.joinId);
      // }
      // this.$Message.warning('暂未开放')
      if(!this.isRegOk){
        this.unverifiedDialog(this);
        return;
      }
      if(!this.getRoleHandle('L1ExShow-L2IM-L3IM')){
        return 
      }
       if(this.userInfo&&this.isServer&&this.userInfo.inMember.exhibitorId==this.exhibitorId){
        this.$Message.warning('无法给自己公司发起洽谈')
        return 
      }
      if (this.companyData && this.companyData.id) {
        this.addAppointmentNumber(this.companyData.id);
      }
      this.talkShow=true;
    },
    //模态框打开
    orderOpen () {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(!this.getRoleHandle('L1Operate-L2YuYue-L3Publish')){
        return 
      }
      this.$refs.orderModal.open();
    },
    swiperPrev () {
      const container = this.$refs.swiper;
      container.swiper.slidePrev();
    },
    swiperNext () {
      const container = this.$refs.swiper;
      container.swiper.slideNext();
    },
    async init () {
      console.log(this.CompMag, "CompMag");
      this.moreInt = false;
      if (this.CompMag && this.CompMag.businessData[0]) {
        this.companyId = this.CompMag.businessData[0].exhibitor.joinId;
        this.getInfo();
        //   this.companyData = await this.$service.getInvite(this, this.companyId);
        //   if (this.companyData.Banner) {
        //     this.banner = JSON.parse(
        //       this.companyData.Banner.replace(/\\'/g, '"')
        //     );
        //     if (this.banner.Design === "undefined") this.banner.Design = "";
        //     if (this.banner.Position === "undefined") this.banner.Position = "";
        //   } else {
        //     this.banner = null;
        //   }
        //   let text = this.isEllipsis(this.companyData.Introduction);
        //   console.log(text,'text');
        //   // console.log(this.banner);
        //   this.ProductData();
        //   this.ActivityData();
        //   this.setFollower();
        //   if (this.CompMag.List[0].JoinId) {
        //     this.hasCollect = await this.isCollect(
        //       this.CompMag.List[0].JoinId
        //     );
        //   } 
        //   this.reqData = {
        //     UserId: this.userInfo.Id,
        //     HostId: this.entranceInfo.HostId,
        //     CompId: this.CompMag.List[0].JoinId // 用参展的id
        //   };
      }
    },
    async getInfo () {
      let query = `
        query{
          exhibitorQuery{
            get(id:"${this.companyId}"){
						banner	      #主图海报
            id
            video	      	#主图视频
            objectives    #参展目标
            activityLiving{
              acvitityType
              id
              liveAuditState
              liveBackgroundUrl
              externalLink
              title
              hasLive
              liveStartTime
              liveEndTime
            }
            customerServiceIsAppointmentNumber
            summaryZh   #参展公司简介
            objectiveTags #参展目标标签 多个按;隔开
            userCount 	  #浏览人数
						shareCount    #分享次数
						followCount   #关注人数
            likeCount	    #点赞数
            advisoryCount #咨询人数
            talkCount
						appointmentCount #预约数量
            activityCount #活动数
            evaluateCount #评价数
            boothInfo
            exhibition{
              banners{
                covers
              }
              banner
            }
            exhibitionId
            businesses(num:3){
              isSample
            }
            category #产品类别
            evaluateTags{ #评价标签
              evaluateCount #评价数
              name
            }  
            proCount      #产品数量
            activityNumber
            productNumber
            appointmentNumber
            activityCount
            activities(num:3){
                id
                title
                titleEn
                banners
                acvitityType
                hasLive
                liveAuditState
            }
            products(num:3){
                id
                name
                nameEn
                banner
                shortName
            }
            continuouNumber #连续参展年份
            isLiving      #是否正在直播
            compId        #公司id 
            company{      #公司信息
              address
              introduction
              id
              name
              logo
              region      #地区
              banners     #banner
              videos      #视频
              country
              province
              city
              website     
              email       
            }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let res = await this.graphqlPost(opt);
      let data = JSON.parse(JSON.stringify(res.data.exhibitorQuery.get));
      if (data.company.banners) {
        data.company.banners = data.company.banners.split(";");
      }
      let text = this.isEllipsis(data.summaryZh);
      this.productData = data.products;
      this.activityData = data.activities;
      // if (!data) return;
      // if (data.company.videos && data.company.videos.indexOf("{") !== -1) {
      //   data.company.videos = JSON.parse(data.company.videos);
      // }
      // if (data.company.banners) {
      //   data.company.banners = data.company.banners.split(";");
      // }
      if (data.category && data.category.indexOf('[') !== -1) {
        let cateArr = JSON.parse(data.category);
        if (cateArr[0]) {
          data.category = this.getCate(cateArr);
        } else {
          data.category = '';
        }
      }
      this.companyData = data;
      console.log(this.companyData, 'this.companyData')
      this.setFollower();
      this.getFollowAndLikeState();
      // data.evaluateCount = this.wCount(data.evaluateCount); //评价数
      // for (let i = 0; i < data.evaluateTags.length; i++) {
      //   data.evaluateTags.evaluateCount = this.wCount(
      //     data.evaluateTags.evaluateCount
      //   );
      // }
      // if (data.objectiveTags) {
      //   data.objectiveTags = data.objectiveTags.split(";");
      // }
      // if (data.company.region) {
      //   let arr = data.company.region.split("/");
      //   data.company.regionTxt =
      //     arr[arr.length - 2] + "·" + arr[arr.length - 1];
      // } else {
      //   data.company.regionTxt = "";
      // }
      // if (data.isLiving) {
      //   data.activityLiving.liveTime =
      //     moment(data.activityLiving.liveStartTime).format("YYYY/MM/DD HH:mm") +
      //     "  " +
      //     moment(data.activityLiving.liveEndTime).format("HH:mm");
      // }
      // this.exInfo = data; 
      // this.setShare();
      // document.title = data.company
      //   ? `${data.company.name + '-'}${this.exhibitionInfo.name}`
      //   : "展商秀";
    },
    getCate (arr) {
      let str = '';
      for (let i = 0; i < arr.length; i++) {
        if(arr[i] && arr[i].Value) {
          str += str ? "," + arr[i].Value : arr[i].Value;
        }
      }
      return str
    },
    isEllipsis (content) { // 计算文字长度
      let el = document.createElement('div')  // 创建一个临时div
      content = content && content.length > 100 ? content.substring(0, 100) : content;
      el.innerHTML = content;
      el.style.position = 'absolute';
      el.style.color = "#333"; // 完全透明
      el.style.width = '357px';
      el.style.fontSize = "14px";
      el.style.lineHeight = "1.5";
      document.body.appendChild(el);
      const elHeight = el.clientHeight; // 获取这个含有content内容的临时div的宽度
      document.body.removeChild(el);
      if (elHeight > 42) {
        let intro = '';
        let textLength = content.length;
        for (var i = 0; i < textLength; i++) {
          el = document.createElement('div')  // 创建一个临时div
          el.innerHTML = content.substring(0, i);
          el.style.position = 'absolute';
          el.style.opacity = 0; // 完全透明
          el.style.width = '357px';
          el.style.fontSize = "14px";
          el.style.lineHeight = "1.5";
          document.body.appendChild(el);
          let elH = el.clientHeight; // 获取这个含有content内容的临时div的宽度
          document.body.removeChild(el);
          if (elH > 42) {
            intro = content.substring(0, i);
            break;
          }
        }
        this.Introduction = intro.substring(0, intro.length - 7) + '...';
        this.moreInt = true;
      } else {
        this.Introduction = content;
      }
    },
    moreIntroduction () {
      this.Introduction = this.companyData.summaryZh;
      this.moreInt = false;
    },

    //获取点赞和关注
    async getFollowAndLikeState () {
      let query = `
            query{
                  exhibitorFollowerQuery{
                  isrFollow(exhibitorId:"${this.companyId}",userId:"${this.userInfo.userId}")
                }
                  exhibitorLikeQuery{
                  isLike(exhibitorId:"${this.companyId}",userId:"${this.userInfo.userId}")
              }
          }
          `;
      let opt = {
        query: query,
        variables: {},
      };

      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data));
      this.hasCollect = res.exhibitorFollowerQuery.isrFollow;
      this.isLike = res.exhibitorLikeQuery.isLike;
      console.log(this.hasCollect, 'this.hasCollect')
    },

    // 获取围观的数据
    setFollower () {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: "exhibitor",
        targetId: this.exhibitorId,
      };
      if (this.userId) {
        opt.userId = this.userId;
      }
      this.addView(opt); // 添加围观数据
      this.getVisit(); // 获取围观人数
    },
    //获得访问信息
    async getVisit () {
      let query = `
        query queryExhibitorUser($page:Int!,$size:Int!,$where:String!,$order:String ){
          queryExhibitorUser(query:{page:$page,size:$size,where:$where,order:$order}){
            hasNextPage
            pageIndex
            totalCount
            items {	
              member{
                avatarUrl
                flagStyle
              }
            }
          }
        }`
      let where = {
        "Equal": {
          "n": "exhibitorId",
          "v": this.companyId
        },
        "Equal_isDeleted": {
          "n": "isDeleted",
          "v": 0
        },
      }
      let order = [
        {
          "N": "createAt",
          "v": 0
        }
      ]
      let opt = {
        query: query,
        variables: {
          page: 1,
          size: 20,
          where: JSON.stringify(where),
          order: JSON.stringify(order)
        },
      };
      let res = await this.graphqlPost(opt);
      if (res.data.queryExhibitorUser.items) {
        this.onlookers = res.data.queryExhibitorUser.items;
        this.FollowerCount = res.data.queryExhibitorUser.totalCount;
      }
    },


    // 路由跳转
    linkTo (msg, type) {
      switch (type) {
        case "com": // 链接到公司
          if (msg.businessData[0] && msg.businessData[0].exhibitor.joinId) {
            var id = msg.businessData[0].exhibitor.joinId;
            let routeData = this.$router.resolve({
              path: `/exhibitor-detail/${id}?exhibitionId=${this.exhibitionId}`
            });
            window.open(routeData.href, '_blank')
          }
          break;
        case "pro": // 链接到展品
          if (msg.id) {
            var id = msg.id;
            let routeData = this.$router.resolve({
              path: `/product-detail?productId=${id}&exhibitionId=${this.exhibitionId}`
            });
            window.open(routeData.href, '_blank')
          }
          break;
        case "act": // 链接到活动
          if (msg.id) {
            var id = msg.id;
            let routeData = this.$router.resolve({
              path: `/home/activity-details?id=${id}&exhibitionId=${this.exhibitionId}`
            });
            window.open(routeData.href, '_blank')
          }
          break;
      }
    },
    // 手机查看；
    mobView (comp) {
      this.showModal = true;
      var compId = comp.exhibitor.joinId ? comp.exhibitor.joinId : "";
      if (compId) {
        this.qrcode.clear();
        this.qrcode.makeCode(`https://${url.mobile}/exhibitor/${compId}`);
      }
    },
    // 点击贸易对接
    tradeClick () {
      if (!this.login) {
        this.$emit("openLogin");
        return;
      }
      this.showTrade = true;
    },
    // 添加到我的收藏
    async addCollect () {
      if (!this.CompMag) return;
      if (this.hasCollect) return;
      const bool = await this.addToCollect(this.CompMag.List[0].JoinId);
      if (bool) {
        this.$Message.success("收藏成功");
        this.init();
      } else {
        this.$Message.error("收藏失败");
      }
    },
    // 取消到我的收藏
    async delCollect () {
      if (!this.CompMag) return;
      const bool = await this.delToCollect(this.CompMag.List[0].JoinId);
      if (bool) {
        this.$Message.success("取消收藏");
        this.init();
      } else {
        this.$Message.error("取消失败");
      }
    },
    // 展位认领
    boothClaimNow () {
      // if (!this.login) {
      //     this.$emit("openLogin");
      //     return;
      // }
      // if(!this.isBoothClaim){
      //   this.$Message.warning("展位认领入口已关闭");
      //   return;
      // }
      this.boothClaimFlag = true;
    },

    // 展位图展开
    BigImg (option) {
      this.bannerModal = true;
      this.boothMap = option;
      // this.$emit('openBigImg',option)
    },

    // 关闭搜索公司信息
    closePd3 () {
      this.$emit('closePd3')
    },

    async addLove () {
      // 展商关注
      // if (!this.userInfo) {
      //   this.$Myapollo.NoUserInfo(this);
      //   return;
      // }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        compId: this.companyId,
        exhibitionId: this.exhibitionId,
        exhibitorId: this.companyId,
        userId: this.userInfo.userId,
        userInfo: JSON.stringify(userInfo),
      };
      let result = await this.addCompLove(opt);
      console.log(result, '213')
      if (result.result) {
        this.hasCollect = true;
      } else {
        this.hasCollect = false;
      }
    },
    async delLove () {
      // if (!this.userInfo) {
      //   this.$Myapollo.NoUserInfo(this);
      //   return;
      // }
      // 取消关注
      let body = {
        exhibitorId: this.companyId,
        userId: this.userInfo.userId,
      };
      let result = await this.delCompLove(body);
      console.log(result, '213');
      if (result.result) {
        this.hasCollect = false;
      } else {
        this.hasCollect = true;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.bot-left {
  display: flex;
  align-items: center;
  span {
    width: 88px;
    height: 26px;
    border-radius: 5px;
    text-align: center;
    line-height: 24px;
    display: inline-block;
    font-size: 14px;
  }
  .bot-view {
    margin-left: 8px;
  }
  .bot-love1 {
    border: 1px solid #1890ff;
    @include border_color(#1890ff);
    @include font_color(#1890ff);
    &.active {
      @include background_color(#1890ff);
      color: #fff;
    }
  }
  .bot-love2 {
    border: solid 1px #bfbfbf;
    color: #bfbfbf;
  }
}
.mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  h3 {
    font-size: 20px;
    color: #333;
  }
  img {
    width: 200px;
    height: 200px;
    margin: 25px 0 50px 0;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
  }
  p {
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }
}
.pd3-cop-view {
  margin-top: 8px;
  h2 {
    span {
      position: absolute;
      right: 8px;
      font-weight: 100;
      color: #666;
      font-size: 12px;
      i {
        font-style: normal;
        font-size: 14px;
      }
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    span {
      width: 10%;
      height: 30px;
      display: block;
      border-radius: 50%;
      margin-bottom: 10px;
      img {
        width: 30px;
        height: 100%;
        display: block;
        border-radius: 50%;
        margin: auto;
      }
    }
  }
  p {
    text-align: center;
    @include font_color(#1890ff);
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
}
.pd3-cop-act .act-list {
  justify-content: flex-start;
}
.goLink {
  cursor: pointer;
}
.pd3-cop {
  overflow-y: auto;
  padding: 20px 20px 0 20px;
}
.pd3-cop-bot {
  // padding-right: 20px;
  padding: 12px 20px;
  margin-top: 0;
  border-top: 1px solid #f2f2f2;
}
.rank_reading {
  margin: 5px 0;
  font-size: 14px;
  line-height: 14px;
  color: #999999;
}
.pd3-cancle {
  position: absolute;
  right: 15px;
  top: 8px;
  .icon {
    font-size: 12px;
    color: #999;
    cursor: pointer;
  }
}
.addr {
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  font-size: 12px;
  @include font_color(#1890ff);
  background-color: rgba(24, 144, 255, 0.15);
  display: inline-block;
}
.pd3-msg {
  display: flex;
  margin: 20px 0;
  align-items: center;
  height: 60px;
  background-color: #f7f7f7;
  border-radius: 5px;
  &-li {
    width: 20%;
    text-align: center;
    position: relative;
    h4 {
      color: #ff5c00;
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      color: #666;
    }
  }
  &-li::after {
    content: "";
    position: absolute;
    right: 0;
    top: 11px;
    width: 1px;
    height: 26px;
    background-color: #ddd;
  }
  &-li:nth-child(5)::after {
    display: none;
  }
}
</style>